/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'

/**
 * 获取下载订单历史
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const getConsumeList = (params) => {
    return request({
        url: serviceInterface.order.consumeList,
        params: params
    })
}


/**
 * 获取充值历史订单
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const getChargeList = (params) => {
    return request({
        url: serviceInterface.order.chargeList,
        params: params
    })
}