<template>
  <div>
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="照片信息">
        <template slot-scope="scope" >
          <img :src="scope.row.img" alt />
          <div class="fr">
            <p class="code">{{scope.row.id}}</p>
            <p class="label">
              {{scope.row.imgType}}
              <span class="size">{{scope.row.imgSize}}</span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下载费用">
        <template slot-scope="scope">
          <div class="price" v-if="scope.row.complete">
            <p class="font-red">扣除：1次</p>
          </div>
          <div class="price" v-else>
            <p class="font-blue" v-if="scope.row.type">单价：1次</p>
            <p class="font-blue" v-else>{{scope.row.msg}}</p>
            <el-button
                type="danger"
                round
                v-if="scope.row.saleType !== null && scope.row.saleType !== ''"
                size="mini"
            >{{scope.row.saleType}}</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图片格式">
        <template slot-scope="scope">
          <el-select v-model="scope.row.value" v-if="scope.row.type" :disabled="scope.row.disabled" placeholder="请选择">
            <el-option
                    v-for="item in scope.row.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope">
          <div class="processBar" v-if="scope.row.loading"></div>
          <div v-else-if="!scope.row.loading && scope.row.complete">
            <a rel="nofollow" :href="scope.row.downurl" target="_self"><el-button type="primary" size="small">点击下载</el-button></a>
            <!--<el-button
                    type="success"
                    size="small"
                    @click="standbyDownload(scope.$index, scope.row)"
            >备用下载</el-button>-->
          </div>
          <div v-else-if="!scope.row.loading && scope.row.error">
            <p> {{scope.row.msg}} </p>
          </div>
          <div v-else>
            <el-button type="text" size="middle" @click="deleteRow(scope.$index, tableData)"><i class="el-icon-close"></i></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :pager-count="11"
            layout="total, sizes, prev, pager, next"
            :total="tableData.length"
            v-if="tableData.length>100"
    ></el-pagination>
    <div class="btn" v-if="!sureflag">
      <el-button type="primary" @click="sureDownload" plain icon="el-icon-edit" :disabled="downflag">确认下载</el-button>
    </div>
    <div class="btn" v-else>
      <!--<el-button @click="allDownload" plain icon="el-icon-edit">打包下载</el-button> -->
      <el-button type="primary" @click="batchDownload" plain icon="el-icon-edit">批量下载</el-button>
    </div>
  </div>
</template>

<script>
  import { hasBlance,buyTemp } from '../../../api/downLoad'
  import { message } from '@/utils/resetMessage';

  export default {
    props: ["data","sureflag","downflag","clearflag"],
    watch:{
      clearflag:{
        handler(val){
          if(val){
            this.urlArray = [];
          }
        }
      }
    },
    computed: {
      tableData() {
        return this.data;
      }
    },
    data() {
      return {
        currentPage: 1,
        pageSize: 10,
        urlArray:[] //批量下载
      };
    },
    mounted() {
      this.cnt.label = '';
    },
    methods: {
      popSuccess() {
        if (document.getElementsByClassName("el-message").length > 0) return;
        this.$message({
          message: "下载成功",
          type: "success",
          showClose: true,
          duration: 2000,
          offset: 80
        });
      },

      //普通下载
      ordinaryDownload(index, row) {
        window.open(row.downurl,"_self");
      },
      //备用下载
      standbyDownload(index, row) {
        window.open(row.downurl2,"_self");
      },
      //确认下载
      sureDownload() {
        this.$emit("onSureDown", false);
        this.$emit("onDownload", true);
        //判断是否够余额
        var ids = '';
        var type = '';
        this.tableData.forEach((item) => {
          if(item.type){
            ids += item.id + ',';
            type = item.webType;
            item.loading = true;
          }
        })
        ids = ids.substring(0, ids.length - 1);//去除最后一个逗号
        let dataform = {
          imageIDs: ids,
          webType: type
        }
        hasBlance(dataform).then(async (result) => {
          if (result) {//如果余额足够，则开始获取下载链接
            let i = 0;
            while (i < this.tableData.length){
              let item = this.tableData[i];
              if(item.type){ //存在才搜索
                let flag = true;
                let buydata = {
                  imageID:item.id,
                  webType:item.webType,
                  format:item.value
                }
                try{
                  let res = await buyTemp(buydata).catch(error=>{
                    flag = false;
                  });
                  if (flag && (res.downloadUrl != '' && res.downloadUrl != null)) {
                    item.complete = true;
                    item.loading = false;
                    item.downurl = res.downloadUrl;
                    item.downurl2 = res.downloadUrl2;
                    item.price = res.price;
                    this.urlArray.push(item.downurl); //批量
                    if (res.time !== '' && res.time != null) {
                      this.$emit("changeTime", res.time);
                    }
                  } else {
                    let res = await buyTemp(buydata).catch(error=>{
                      flag = false;
                    });
                    if (flag && (res.downloadUrl != '' && res.downloadUrl != null)) {
                      item.complete = true;
                      item.loading = false;
                      item.downurl = res.downloadUrl;
                      item.downurl2 = res.downloadUrl2;
                      item.price = res.price;
                      this.urlArray.push(item.downurl); //批量
                      if (res.time !== '' && res.time != null) {
                        this.$emit("changeTime", res.time);
                      }
                    } else {
                      item.loading = false;
                      item.error = true;
                      item.msg = '下载失败，请联系客服';
                    }
                  }
                }catch(e){
                  //再试一次
                  try{
                    let res = await buy(buydata).catch(error => {
                      flag = false;
                    });
                    if(flag && (res.downloadUrl != '' && res.downloadUrl != null)){
                      item.complete = true;
                      item.loading = false;
                      item.downurl = res.downloadUrl;
                      item.downurl2 = res.downloadUrl2;
                      item.price = res.price;
                    }else{
                      item.loading = false;
                      item.error = true;
                      item.msg = '下载失败，未扣费，请您联系客服处理';
                    }
                  }catch(e){
                      item.loading = false;
                      item.error = true;
                      item.msg = '下载失败，未扣费，请您联系客服处理';
                  }
                }
              }
              if(this.tableData.length == (i+1)) { //判断是否最后一个
                this.$emit("onSureDown", true);
                this.$emit("onDownload", false);
              }
              i++;
            }
          } else {//否则提示
            this.$emit("onSureDown", false);
            this.$emit("onDownload", false);
            message.success({
              message: '您的余额已不足，请充值',
              duration: 2000,
              offset: 80
            })
          }
        }).catch((error) => {
          //余额不足出异常时
          this.$emit("noBanlance", error);
          this.$emit("onSureDown", false);
          this.$emit("onDownload", false);
        })
      },
      async downloadOne(i){ //下载单个

      },
      //全部下载
      allDownload() {
        this.popSuccess();
      },
      //批量下载
      batchDownload() {
        for(var g = 0; g<this.urlArray.length;g++){
          window.open(this.urlArray[g]);
        }
      },
      deleteRow(index, row) {
        row.splice(index, 1);
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
//进度条
.processBar {
  width: 240px;
  height: 7px;
  border: 1px solid #0059ca;
  border-radius: 2px;
  animation: progress-bar-stripes 2s linear infinite;
  background-color: #0773fc;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  opacity: 1;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.table {
  .el-table__body {
    img {
      margin-right: 10px;
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    .fr {
      .code {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #578ade;
        .label {
          font-size: 16px;
          color: #43424b;
        }
      }
      .size {
        padding-left: 10px;
        font-size: 10px !important;
      }
    }
    .el-button--mini.is-round {
      padding: 4px 10px;
      span {
        font-size: 10px;
      }
    }
    .price {
      .font-blue {
        color: #0773fc;
      }
      .font-red {
        color: #f56c6c;
      }
      .font-green {
        color: #67c23a;
      }
    }
  }
}
.btn {
  margin: 20px 0 100px;
  .el-button {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
      font-size: 16px;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
}
.el-pagination {
  margin: 20px 0 30px;
}
</style>
